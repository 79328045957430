import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import { withRouter } from "react-router";
import {
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Link,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import BurgerIcon from "../icons/BurgerIcon";
import GardenBlackIcon from "../assets/GardenBlackIcon.png";
import GardenGreenIcon from "../assets/GardenGreenIcon.png";
import FarmerBlackIcon from "../assets/FarmerBlackIcon.png";
import FarmerGreenIcon from "../assets/FarmerGreenIcon.png";
import LogoutBlackIcon from "../assets/LogoutBlackIcon.png";
import { withStyles } from "@material-ui/core/styles";
import logo from "../assets/logo.svg";

const styles = (theme) => ({
  burger: {
    "& span": {
      justifyContent: "flex-end",
    },
    "& svg": {
      fontSize: "2rem",
    },
  },
  menuButton: {
    marginRight: "61px",
    fontWeight: "500",
    "& span": {
      fontSize: "1.2rem",
    },
  },
  logo: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: "#FFFFFF",
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#000",
  },
  nav: {
    marginTop: theme.spacing(6),
  },
  selected: {
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: "600",
    },
  },
});

class TopBar extends Component {
  state = {
    isOpen: false,
  };

  toggleDrawer = () => {
    const { isOpen } = this.state;
    !isOpen
      ? this.setState({ isOpen: true })
      : this.setState({ isOpen: false });
  };

  render() {
    const { classes, /* theme, */ isAuthenticated } = this.props;
    const { isOpen } = this.state;
    const { pathname } = this.props.location;
    return (
      <AppBar position="sticky" className={classes.appbar} elevation={0}>
        <Toolbar>
          <Link component={RouterLink} to="/" className={classes.logo}>
            <img src={logo} alt="plantiful.io" />
          </Link>
          {isAuthenticated && (
            <>
              <Button onClick={this.toggleDrawer} className={classes.burger}>
                <BurgerIcon />
              </Button>
              <Drawer
                variant="temporary"
                anchor="right"
                open={isOpen}
                onBackdropClick={this.toggleDrawer}
              >
                <IconButton
                  aria-label="close"
                  className={classes.close}
                  onClick={this.toggleDrawer}
                >
                  <CloseIcon />
                </IconButton>
                <List component="nav" className={classes.nav}>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/"
                    key="garden"
                    onClick={this.toggleDrawer}
                  >
                    <ListItemIcon>
                      <img
                        src={
                          pathname === "/" ? GardenGreenIcon : GardenBlackIcon
                        }
                        alt="garden icon"
                        width="36px"
                        height="36px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Garden"
                      className={`${classes.menuButton} ${
                        pathname === "/" && classes.selected
                      }`}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={RouterLink}
                    to="/profile"
                    key="profile"
                    onClick={this.toggleDrawer}
                  >
                    <ListItemIcon>
                      <img
                        src={
                          pathname === "/profile"
                            ? FarmerGreenIcon
                            : FarmerBlackIcon
                        }
                        alt="farmer black icon"
                        width="36px"
                        height="36px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Profile"
                      className={`${classes.menuButton} ${
                        pathname === "/profile" && classes.selected
                      }`}
                    />
                  </ListItem>
                  <ListItem
                    button
                    key="logout"
                    onClick={() => {
                      this.toggleDrawer();
                      this.props.onLogout();
                    }}
                  >
                    <ListItemIcon>
                      <img
                        src={LogoutBlackIcon}
                        alt="logout black icon"
                        width="36px"
                        height="36px"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Logout"
                      className={classes.menuButton}
                    />
                  </ListItem>
                </List>
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(TopBar));
