import React from "react";
import TodayPlant from "./TodayPlant";
import { Grid, GridList, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

//Material-ui - initialize theme & define custom CSS classes
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    overflow: "hidden",
    marginLeft: "-22px",
    marginRight: "-22px",
  },
  body: {
    color: theme.palette.london.main,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
    paddingLeft: "22px",
  },
}));

const TodayList = (props) => {
  const classes = useStyles(); //create CSS classes
  const { plants, updatePlant } = props; //get props from parent
  const todayPlants = plants.filter((plant) => needWater(plant)); //get list of plants that need attention

  //Check if plant needs water based on last wateredAt data and frequency of watering
  function needWater(plant) {
    let frequency = parseInt(plant.plantFrequency);
    let wateredAtDate = new Date(plant.plantWateredAt);
    let dueDate = new Date(
      wateredAtDate.setDate(wateredAtDate.getDate() + frequency)
    );
    let diff = Date.parse(dueDate) - Date.parse(new Date());
    if (diff > 0) return false;
    return true;
  }

  function handleWater(plantId) {
    updatePlant(plantId);
  }

  return (
    <div className={classes.root}>
      {todayPlants.length === 0 ? (
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h3">
              <span role="img" aria-label="clap emoji">
                👏
              </span>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" color="secondary">
              Well done!
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.body}>
              Nothing to see there
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <GridList className={classes.gridList} spacing={2}>
          {todayPlants.map((plant) => (
            <TodayPlant
              key={plant.plantId}
              water={handleWater}
              plant={plant}
              //ref={this.getOrCreateRef(plant.plantId)}
            />
          ))}
        </GridList>
      )}
    </div>
  );
};

export default TodayList;
