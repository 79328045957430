import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: {
    fill: "#F9F1E7",
  },
  st1: {
    fill: "none",
    stroke: "#F7CFB2",
    strokeWidth: "1.7",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

const RepotedIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle
        id="Oval-Copy"
        className={classes.st0}
        cx="29.5"
        cy="15.5"
        r="13.5"
      />
      <path
        id="Stroke-34"
        className={classes.st1}
        d="M6.3,26.8c1.1-3.7,1.7-3.7,4.7-4.9c1.6-5.3,1.6-6.1,6-7.5c2.4-5.3,4.5-8,9.4-1.9
	c3.5,0,3.5,0.3,6.5,3.7c1,0.4,2,0.8,3,1.1 M1,37.4c1.7-1.1,3.3-2.3,4.7-3.9c1.4-1.6,1.5-5.2,6.8-7.4c3.3-1.3,5.6-2.5,8.1-3.6
	c1.6-0.7,3-1.2,4,0.1c0.9,1.2,0.1,2.4-1,3.3c-2.3,1.6-3.7,2.6-6.5,4.2c4.2,0.3,6.5,0.3,12.4-3.4c1.1-0.7,5.1-4.6,6.2-5.7
	c0.7-0.7,1.9-1.1,2.7-0.3c0.7,0.7,0.8,1.8,0.1,2.6c-1.4,1.7-4.3,5.3-5.5,6.4c-1.7,1.6-6.4,5.4-9.2,6c-4.4,0.9-8,0.5-12.5,4.4"
      />
    </SvgIcon>
  );
};

export default RepotedIcon;
