import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import TopBar from "./components/TopBar";
import Notifications from "./components/Notifications";
import Routes from "./Routes";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { onError } from "./libs/errorLib";
import ErrorBoundary from "./components/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    backgroundColor: "#FBF9F4",
  },
  app: {
    backgroundColor: "#FBF9F4",
    position: "relative",
  },
}));

function App() {
  const classes = useStyles();
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const notificationsRef = useRef(null);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
        notificationsRef.current.open("error", e.message);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <ThemeProvider theme={theme}>
        <>
          <CssBaseline />
          <div className={classes.root}>
            <TopBar isAuthenticated={isAuthenticated} onLogout={handleLogout} />
            <div className={classes.app}>
              <ErrorBoundary>
                <AppContext.Provider
                  value={{
                    isAuthenticated,
                    userHasAuthenticated,
                    notificationsRef,
                  }}
                >
                  <Routes />
                </AppContext.Provider>
              </ErrorBoundary>
            </div>
            <Notifications ref={notificationsRef} />
          </div>
        </>
      </ThemeProvider>
    )
  );
}

export default App;
