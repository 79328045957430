import React from "react";
import { CircularProgress, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./LoaderButton.css";

const useStyles = makeStyles((theme) => ({
  /* wrapper: {
    position: "relative",
  }, */
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoaderButton({
  isLoading,
  className,
  disabled = false,
  ...props
}) {
  const classes = useStyles();
  return (
    //<div className={classes.wrapper}>
    <Button className={className} disabled={disabled || isLoading} {...props}>
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
      {props.children}
    </Button>
    //</div>
  );
}
