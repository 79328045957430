const dev = {
  // STRIPE_KEY: "YOUR_STRIPE_DEV_PUBLIC_KEY",
  s3: {
    REGION: "eu-west-1",
    BUCKET: "dev-plantiful-backend-s3-uploads4f6eb0fd-1cdt0zhfk2i1i"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://api.plantiful.io/dev"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_FZqX50Sro",
    APP_CLIENT_ID: "215jvd2urcm3lbi1f4p6ft67p6",
    IDENTITY_POOL_ID: "eu-west-1:a8b7410a-a117-48ca-bf47-1491fa868748"
  }
};

const prod = {
  // STRIPE_KEY: "YOUR_STRIPE_PROD_PUBLIC_KEY",
  s3: {
    REGION: "eu-west-1",
    BUCKET: "prod-plantiful-backend-s3-uploads4f6eb0fd-hhoczsgloghh"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://api.plantiful.io/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_X3jj6ZqCZ",
    APP_CLIENT_ID: "ouofj0e4t5allq6386f0tgqjt",
    IDENTITY_POOL_ID: "eu-west-1:475e7b98-9afe-4836-a004-15bf02a985ff"
  }
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;