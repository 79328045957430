import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  st1: {
    fill: "#9ea09f",
    fillRule: "evenodd",
  },
}));

const CalendarIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 25 26" className={classes.icon} {...props}>
      <path
        id="Fill-3"
        className={classes.st1}
        d="M16,3.1H8.8a.47.47,0,0,1-.5-.5.47.47,0,0,1,.5-.5H16a.47.47,0,0,1,.5.5.47.47,0,0,1-.5.5"
      />
      <path
        className={classes.st1}
        d="M21.2,2.1h-1a.47.47,0,0,0-.5.5.47.47,0,0,0,.5.5h1a2.65,2.65,0,0,1,2.6,2.6V7.3H1V5.7A2.67,2.67,0,0,1,3.7,3.1h1a.46.46,0,0,0,.4-.5.47.47,0,0,0-.5-.5h-1A3.59,3.59,0,0,0,0,5.7V21.2a3.59,3.59,0,0,0,3.6,3.6H21.2a3.59,3.59,0,0,0,3.6-3.6V5.7A3.59,3.59,0,0,0,21.2,2.1Zm0,21.7H3.6A2.65,2.65,0,0,1,1,21.2V8.3H23.8V21.2A2.65,2.65,0,0,1,21.2,23.8Z"
      />
      <path
        id="Fill-6"
        className={classes.st1}
        d="M6.7,5.2a.47.47,0,0,1-.5-.5V.5a.5.5,0,0,1,1,0V4.6a.56.56,0,0,1-.5.6"
      />
      <path
        id="Fill-7"
        className={classes.st1}
        d="M18.1,5.2a.47.47,0,0,1-.5-.5V.5a.5.5,0,0,1,1,0V4.6a.56.56,0,0,1-.5.6"
      />
      <path
        id="Fill-8"
        className={classes.st1}
        d="M8.8,20.7a2.65,2.65,0,0,1-2.6-2.6.5.5,0,0,1,1,0,1.6,1.6,0,1,0,1.6-1.6h-1a.5.5,0,0,1,0-1h1a1.6,1.6,0,1,0-1.6-1.6.5.5,0,0,1-1,0,2.65,2.65,0,0,1,2.6-2.6,2.65,2.65,0,0,1,2.6,2.6,2.81,2.81,0,0,1-1,2.1,2.63,2.63,0,0,1-1.6,4.7"
      />
      <path
        className={classes.st1}
        d="M18.1,19.7H16.6V12a.55.55,0,0,0-.3-.5.61.61,0,0,0-.6.1l-2.1,2.1a.49.49,0,1,0,.7.7l1.2-1.2v6.5H14a.5.5,0,0,0,0,1h4.1a.5.5,0,0,0,0-1Z"
      />
    </SvgIcon>
  );
};

export default CalendarIcon;
