import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  Link,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockIcon from "../icons/LockIcon";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { Auth } from "aws-amplify";

/* function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://plantiful.io/">
        plantiful.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
} */

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: "12px",
  },
  mask: {
    cursor: "pointer",
    fill: theme.palette.london.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  alternate: {
    display: "block",
    margin: theme.spacing(4, 0, 0),
  },
}));

export default function Signup() {
  const classes = useStyles();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const [passwordMask, setPasswordMask] = useState(true);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated, notificationsRef } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return Object.values(errors).every((x) => x === "");
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  function validateInput(e) {
    let inputFeedback = {};
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    inputFeedback.email = /^$|.+@.+\..+/.test(fields.email)
      ? ""
      : "Your email must be a valid email address.";
    inputFeedback.password =
      fields.password.length === 0 || strongRegex.test(fields.password)
        ? ""
        : "Your password must contain at least 8 characters including an uppercase, a lowercase and a number.";
    inputFeedback.confirmPassword =
      fields.confirmPassword.length === 0 ||
      fields.password === fields.confirmPassword
        ? ""
        : "This password must match the above password.";
    setErrors({
      ...inputFeedback,
    });
  }

  function togglePasswordMask(e) {
    passwordMask ? setPasswordMask(false) : setPasswordMask(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <form className={classes.form} onSubmit={handleConfirmationSubmit}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="confirmationCode"
          type="tel"
          label="Confirmation code"
          name="confirmationCode"
          InputLabelProps={{ required: false }}
          helperText="Please check your email for the code."
          value={fields.confirmationCode}
          onChange={handleFieldChange}
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          className={classes.submit}
          type="submit"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
        <Grid container justify="center">
          <Grid item>
            <Link component={RouterLink} to="/login" variant="body2">
              Already have an account? Log in
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }

  function renderForm() {
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="email"
          type="email"
          label="Email"
          name="email"
          InputLabelProps={{ required: false }}
          autoComplete="email"
          value={fields.email}
          onChange={handleFieldChange}
          onBlur={validateInput}
          {...(errors.email && { error: true, helperText: errors.email })}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          id="password"
          type={passwordMask ? "password" : "text"}
          label="Password"
          name="password"
          InputLabelProps={{ required: false }}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordMask ? (
                  <Visibility
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                ) : (
                  <VisibilityOff
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={fields.password}
          onChange={handleFieldChange}
          onBlur={validateInput}
          {...(errors.password && {
            error: true,
            helperText: errors.password,
          })}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          id="confirmPassword"
          type={passwordMask ? "password" : "text"}
          label="Confirm Password"
          name="confirmPassword"
          InputLabelProps={{ required: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordMask ? (
                  <Visibility
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                ) : (
                  <VisibilityOff
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={fields.confirmPassword}
          onChange={handleFieldChange}
          onBlur={validateInput}
          {...(errors.confirmPassword && {
            error: true,
            helperText: errors.confirmPassword,
          })}
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className={classes.submit}
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Sign up
        </LoaderButton>
        <Grid container justify="center">
          <Grid item>
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              className={classes.alternate}
            >
              <i>Already have an account? Log in</i>
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <LockIcon />
        <Typography component="h1" variant="h2" className={classes.title}>
          Sign up
        </Typography>
        {newUser === null ? renderForm() : renderConfirmationForm()}
      </div>
      {/* <Box mt={5}>
        <Copyright />
      </Box> */}
    </Container>
  );
}
