import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: {
    fill: "#F9F1E7",
  },
  st1: {
    fill: "#F7CFB2",
    stroke: "#F7CFB2",
    strokeWidth: "0.3",
  },
}));

const AdoptionIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle
        id="Oval-Copy-3"
        className={classes.st0}
        cx="27.5"
        cy="21.5"
        r="13.5"
      />
      <g id="Group-4" transform="translate(0.000000, 2.000000)">
        <g id="Group-12">
          <path
            id="Fill-7"
            className={classes.st1}
            d="M4.8,22.6l11.7,11.6l11.9-13.4c1.1-1.3,1.1-3.2-0.1-4.4L22.8,11c-1.2-1.2-3.1-1.2-4.4-0.1
			L4.8,22.6z M16.6,36c-0.2,0-0.4-0.1-0.5-0.2L3.2,23.1C3.1,23,3,22.8,3,22.6c0-0.2,0.1-0.4,0.2-0.5L17.4,9.7
			c1.8-1.6,4.7-1.5,6.4,0.2l5.5,5.5c1.7,1.7,1.8,4.5,0.2,6.3l-12.4,14C17,35.9,16.8,36,16.6,36C16.6,36,16.6,36,16.6,36L16.6,36z"
          />
          <path
            id="Fill-8"
            className={classes.st1}
            d="M40.2,27.3c-0.1,0-0.2,0-0.3,0.1l-5.1,5.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3
			c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l5.1-5.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			C40.3,27.3,40.2,27.3,40.2,27.3z M35,34.5c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3l5.1-5.1
			c0.7-0.7,1.9-0.7,2.6,0c0.3,0.3,0.5,0.8,0.5,1.3c0,0.5-0.2,0.9-0.5,1.3L36.3,34C36,34.4,35.5,34.5,35,34.5L35,34.5z"
          />
          <path
            id="Fill-1"
            className={classes.st1}
            d="M9.2,18.4c-0.2,0-0.5-0.1-0.6-0.3c-1.8-2.6-1.5-6,0.8-8.2c2.4-2.4,6.4-2.5,9-0.3
			c0.3,0.3,0.3,0.7,0.1,1c-0.3,0.3-0.7,0.3-1,0.1c-2-1.8-5.1-1.7-7,0.2c-1.7,1.7-2,4.4-0.6,6.4c0.2,0.3,0.1,0.8-0.2,1
			C9.5,18.4,9.4,18.4,9.2,18.4"
          />
          <path
            id="Fill-4"
            className={classes.st1}
            d="M35,31.9C35,31.9,35,31.9,35,31.9l-13.6-1.3c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.4-0.7,0.8-0.6
			l13.2,1.2l2.3-2.2l-12.6-1.2c-0.4,0-0.7-0.4-0.7-0.8c0-0.4,0.4-0.7,0.8-0.6l14.1,1.3c0.3,0,0.5,0.2,0.6,0.5c0.1,0.3,0,0.6-0.2,0.8
			l-3.6,3.6C35.4,31.9,35.2,31.9,35,31.9"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default AdoptionIcon;
