import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { withStyles } from "@material-ui/core/styles";
import TimeAgo from "react-timeago";

const styles = (theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
});

export class DueAt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dueDate: new Date(),
      diff: 0,
    };
  }

  componentDidMount() {
    const due = this.calculateDueDate(this.props.wateredAt);
    this.setState(due);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wateredAt !== this.props.wateredAt) {
      const due = this.calculateDueDate(this.props.wateredAt);
      this.setState(due);
    }
  }

  calculateDueDate(wateredAt) {
    let frequency = parseInt(this.props.frequency);
    let wateredAtDate = new Date(wateredAt);
    let dueDate = new Date(
      wateredAtDate.setDate(wateredAtDate.getDate() + frequency)
    );
    let diff = Math.round(
      (Date.parse(dueDate) - Date.parse(new Date())) / 86400000
    );

    const due = {
      dueDate: dueDate,
      diff: diff,
    };

    return due;
  }

  formatter = (value, unit, suffix) => {
    if (value > 1) {
      unit = unit + "s";
    }
    if (suffix === "ago") {
      return `Due ${value} ${unit} ${suffix}`;
    } else {
      return `Due in ${value} ${unit}`;
    }
  };

  render() {
    const { dueDate, diff } = this.state;
    const { classes, className } = this.props;
    return (
      <Typography variant="body2" component="span" className={className}>
        <Grid container direction="row" alignItems="center">
          <FiberManualRecordIcon
            fontSize="inherit"
            className={
              diff >= 0
                ? classes.success
                : diff < -7
                ? classes.error
                : classes.warning
            }
          />
          <TimeAgo
            date={dueDate}
            formatter={(value, unit, suffix) =>
              this.formatter(value, unit, suffix)
            }
          />
        </Grid>
      </Typography>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DueAt);
