import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: {
    fill: "#F9F1E7",
  },
  st1: {
    fill: "none",
    stroke: "#F7CFB2",
    strokeWidth: "1.7",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

const AdoptionIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle id="Oval" className={classes.st0} cx="22.5" cy="16.5" r="13.5" />
      <path
        id="Stroke-68"
        className={classes.st1}
        d="M23.3,21.8c1.6,3.2-0.7,7-4.4,7s-6-3.8-4.3-7c1.4-2.8,2.9-5.7,4.3-8.5
	C20.4,16.1,21.8,19.1,23.3,21.8z"
      />
      <path
        id="Stroke-69"
        className={classes.st1}
        d="M14.7,13.2c1,2-0.4,4.4-2.7,4.4c-2.3,0-3.8-2.4-2.7-4.4c0.9-1.8,1.8-3.6,2.7-5.4
	C12.9,9.6,13.8,11.5,14.7,13.2z"
      />
      <path
        id="Stroke-70"
        className={classes.st1}
        d="M27.6,12.5c0.7,1.4-0.3,3.2-2,3.2c-1.7,0-2.7-1.7-2-3.2c0.7-1.3,1.3-2.6,2-3.9
	C26.2,9.9,26.9,11.3,27.6,12.5z"
      />
      <path
        id="Stroke-71"
        className={classes.st1}
        d="M38,10.7l-2.1,3.6l-3.4-2.2 M15.6,1.3C24.7-0.3,33.8,5.5,36,14.5 M15.9,39l-2.1-3.6l3.6-1.8
	 M35.4,24.6C32.2,33.1,22.6,38,13.7,35.4 M2.2,6l4.2,0l-0.2,4 M5.1,29.8c-5.9-7-5.4-17.7,1.4-24"
      />
    </SvgIcon>
  );
};

export default AdoptionIcon;
