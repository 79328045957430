import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import LockIcon from "../icons/LockIcon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  back: {
    marginLeft: "8px",
    marginTop: "8px",
    color: "#000",
  },
  title: {
    marginTop: "12px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangeEmail() {
  const classes = useStyles();
  const history = useHistory();
  const { notificationsRef } = useAppContext();
  const [codeSent, setCodeSent] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
  });
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errors, setErrors] = useState({});

  /* function validateEmailForm() {
    return fields.email.length > 0;
  } */

  function validateConfirmForm() {
    return fields.code.length > 0;
  }

  function validateEmailForm() {
    return Object.values(errors).every((x) => x === "");
  }

  function validateInput(e) {
    let inputFeedback = {};
    inputFeedback.email = /^$|.+@.+\..+/.test(fields.email)
      ? ""
      : "Your email must be a valid email address.";
    setErrors({
      ...inputFeedback,
    });
  }

  async function handleUpdateClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: fields.email });
      setCodeSent(true);
      notificationsRef.current.open("success", "Confirmation email sent");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", fields.code);

      history.push("/profile");
      notificationsRef.current.open("success", "Email changed");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsConfirming(false);
    }
  }

  function renderUpdateForm() {
    return (
      <form className={classes.form} onSubmit={handleUpdateClick}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="email"
          type="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={fields.email}
          onChange={handleFieldChange}
          onBlur={validateInput}
          {...(errors.email && { error: true, helperText: errors.email })}
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className={classes.submit}
          isLoading={isSendingCode}
          disabled={!validateEmailForm()}
        >
          Send confirmation email
        </LoaderButton>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form className={classes.form} onSubmit={handleConfirmClick}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="code"
          type="tel"
          label="Confirmation code"
          onChange={handleFieldChange}
          value={fields.code}
          helperText="Please check your email for the code."
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className={classes.submit}
          isLoading={isConfirming}
          disabled={!validateConfirmForm()}
        >
          Confirm
        </LoaderButton>
      </form>
    );
  }

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.back}
        component={RouterLink}
        to="/profile"
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <LockIcon />
          <Typography component="h1" variant="h2" className={classes.title}>
            Edit email
          </Typography>
          {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
        </div>
      </Container>
    </>
  );
}
