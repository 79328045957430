import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      color: "#0A0B09",
    },
    h3: {
      fontSize: "1.7rem",
      fontWeight: 600,
      color: "#0A0B09",
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 600,
      color: "#0A0B09",
    },
    body1: {
      color: "#0A0B09",
    },
    body2: {
      fontSize: "0.875rem",
      color: "#9EA09F",
    },
  },
  palette: {
    primary: {
      main: "#517a6a",
      dark: "#38554a",
      light: "#EAECE7",
    },
    secondary: {
      main: "#F7CFB2",
      light: "#F9F1E7",
    },
    error: {
      main: "#E85D5D",
      light: "#F7DBD7",
    },
    warning: {
      main: "#eba902",
    },
    black: {
      main: "#0A0B09",
    },
    aspen: {
      main: "#FBF9F4",
    },
    london: {
      main: "#9EA09F",
    },
  },
});

theme.props = {
  MuiTextField: {
    variant: "filled",
  },
};

theme.overrides = {
  MuiButton: {
    disabled: {
      backgroundColor: `${theme.palette.london.main} !important`,
      color: `${theme.palette.aspen.main} !important`,
    },
    root: {
      textTransform: "inherit",
      fontWeight: 700,
    },
    containedPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    containedSecondary: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    containedSizeLarge: {
      padding: "12px 22px",
      fontSize: "1.125rem",
    },
  },
  MuiIconButton: {
    colorPrimary: {
      backgroundColor: theme.palette.primary.light,
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
        "@media (hover: none)": {
          backgroundColor: theme.palette.primary.light,
        },
      },
      "&:active": {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  MuiChip: {
    root: {
      fontWeight: 600,
    },
    clickable: {
      "&:active": {
        boxShadow: "none",
      },
    },
    colorSecondary: {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.light,
    },
    clickableColorSecondary: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  MuiFab: {
    root: {
      boxShadow:
        "3.3px 3.3px 5.3px rgba(0, 0, 0, 0.04),11.2px 10.9px 17.9px rgba(0, 0, 0, 0.06),50px 49px 80px rgba(0, 0, 0, 0.1)",
    },
  },
  MuiInputBase: {
    input: {
      fontWeight: "500",
    },
  },
  MuiTextField: {
    root: {
      marginBottom: "18px",
    },
  },
  MuiFilledInput: {
    root: {
      backgroundColor: theme.palette.common.white,
      minWidth: "200px",
      "&$focused": {
        backgroundColor: theme.palette.common.white,
      },
      "@media (hover:none)": {
        backgroundColor: theme.palette.common.white,
        "&:hover": {
          backgroundColor: "#FFF !important",
        },
      },
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      "& .MuiInputAdornment-root p": {
        color: theme.palette.london.main,
        fontWeight: "500",
      },
      "&$focused .MuiInputAdornment-root p, .MuiInputAdornment-root.notEmpty p ":
        {
          color: "rgb(0 0 0 / 87%)",
        },
    },
    underline: {
      "&:before": {
        borderBottom: `2px solid ${theme.palette.london.main}`,
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: `2px solid ${theme.palette.london.main}`,
      },
    },
  },
  MuiSelect: {
    select: {
      "&:focus": {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  MuiInputLabel: {
    filled: {
      color: theme.palette.london.main,
      fontWeight: "500",
      "&$focused": {
        color: theme.palette.primary.main,
        fontWeight: "500",
      },
      ".MuiFormControl-root:hover &:not($focused)": {
        color: theme.palette.primary.main,
        fontWeight: "500",
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  },
};

export default theme;
