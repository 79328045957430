import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: {
    fill: "#F9F1E7",
  },
  st1: {
    fill: "none",
    stroke: "#F7CFB2",
    strokeWidth: "1.224",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

const AdoptionIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle
        id="Oval-Copy-2"
        className={classes.st0}
        cx="25.2"
        cy="14.8"
        r="10.8"
      />
      <path
        id="Stroke-36"
        className={classes.st1}
        d="M13.9,14.2C10.2,18,7.8,20.3,4,24c2,2,4,4,6.1,6c2,2,4,4,6.1,6c3.8-3.7,6.2-6.1,9.9-9.8
	C35.6,16.8,23.6,4.7,13.9,14.2z"
      />
      <path
        id="Stroke-37"
        className={classes.st1}
        d="M7.4,23.8c4.7-0.8,8-0.9,11.9-0.1L7.4,23.8z M22.2,24.3c-2.3,4.4-10.3,3.6-12.4-1
	C12.1,18.9,20.1,19.7,22.2,24.3z"
      />
      <path
        id="Stroke-38"
        className={classes.st1}
        d="M25.4,14.9c0.8,0.8,0.8,2,0,2.8c-0.8,0.8-2.1,0.8-2.9,0c-0.8-0.8-0.8-2,0-2.8
	C23.3,14.1,24.6,14.1,25.4,14.9z M29.2,16.6c1.4-0.7,2.3-2.2,2.3-3.8c0-2.4-2-4.3-4.4-4.3s-4.4,1.9-4.4,4.3c0,1.9,1.3,3.6,3,4.1
	L29.2,16.6z"
      />
    </SvgIcon>
  );
};

export default AdoptionIcon;
