import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  primary: {},
  secondary: {},
  droplet: {
    fill: "none",
    stroke: theme.palette.aspen.main,
    strokeWidth: "2",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    "&$primary": {
      stroke: theme.palette.aspen.main,
    },
    "&$secondary": {
      stroke: theme.palette.primary.main,
    },
  },
  down: {
    transform: "translate(-24px, 24px)",
  },
  fill: {
    transform: "translate(-24px, 24px)",
    animation: `$fill 1.5s ease-in infinite`,
  },
  wave: {
    fill: theme.palette.aspen.main,
    "&$primary": {
      fill: theme.palette.primary.main,
    },
    "&$secondary": {
      fill: theme.palette.primary.main,
    },
  },
  "@keyframes fill": {
    "0%": {
      transform: "translate(-24px, 24px)",
    },
    "100%": {
      transform: "translate(0, -5px)",
    },
  },
}));

const DropletIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon>
      <defs>
        <clipPath id="clip">
          <path
            d="M20.8,15.3c1.7,3.1-0.7,6.7-4.5,6.7s-6.1-3.7-4.5-6.7c1.5-2.8,3-5.5,4.5-8.3
	C17.9,9.7,19.3,12.6,20.8,15.3z"
          />
          <path
            d="M8.5,7.5c1,2-0.4,4.5-2.8,4.5C3.4,12,1.9,9.5,3,7.5C3.9,5.7,4.8,3.8,5.8,2
	C6.7,3.8,7.6,5.7,8.5,7.5z"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip)">
        <g
          className={props.isLoading ? classes.fill : classes.down}
          //style={{ transform: "translate(-24px, 24px)" }}
        >
          <path
            className={`${classes.wave} ${classes[props.color] || ""}`}
            d="M40,40V0.3c0,0-0.1,0-0.1,0c0,0-3.4-0.3-5.4-0.3c-2,0-5.4,0.3-5.4,0.3c-1.6,0.1-4,0.2-4.3,0.3c-0.3,0-2.6-0.1-4.3-0.3
	c0,0-3.4-0.3-5.4-0.3c-2,0-5.4,0.3-5.4,0.3C8,0.5,5.6,0.6,5.4,0.6c-0.3,0-2.7-0.1-4.3-0.3c0,0-0.4,0-1.1-0.1V40H40z"
          />
        </g>
      </g>
      <path
        className={`${classes.droplet} ${classes[props.color] || ""}`}
        d="M20.8,15.3c1.7,3.1-0.7,6.7-4.5,6.7s-6.1-3.7-4.5-6.7c1.5-2.8,3-5.5,4.5-8.3
	C17.9,9.7,19.3,12.6,20.8,15.3z"
      />
      <path
        className={`${classes.droplet} ${classes[props.color] || ""}`}
        d="M8.5,7.5c1,2-0.4,4.5-2.8,4.5C3.4,12,1.9,9.5,3,7.5C3.9,5.7,4.8,3.8,5.8,2
	C6.7,3.8,7.6,5.7,8.5,7.5z"
      />
    </SvgIcon>
  );
};

export default DropletIcon;
