import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BurgerIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <line
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.8",
          strokeLinecap: "round",
        }}
        x1="2"
        y1="12"
        x2="22"
        y2="12"
      />
      <line
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.8",
          strokeLinecap: "round",
        }}
        x1="2"
        y1="5.1"
        x2="16.4"
        y2="5.1"
      />
      <line
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.8",
          strokeLinecap: "round",
        }}
        x1="2"
        y1="18.9"
        x2="16.4"
        y2="18.9"
      />
    </SvgIcon>
  );
};

export default BurgerIcon;
