import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: { fillRule: "evenodd", clipRule: "evenodd", fill: "#EAECE7" },
  st1: { fill: "none", stroke: "#517A6A", strokeWidth: "1.8" },
  st2: {
    fill: "none",
    stroke: "#517A6A",
    strokeWidth: "1.8",
    strokeLinecap: "round",
  },
  st3: {
    fill: "none",
    stroke: "#517A6A",
    strokeWidth: "1.2623",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

const LockIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle id="Oval" className={classes.st0} cx="30.8" cy="18.3" r="11.7" />
      <path
        id="Rectangle"
        className={classes.st1}
        d="M7.9,19.7h28c1,0,1.9,0.8,1.9,1.9v20.6c0,1-0.8,1.9-1.9,1.9h-28c-1,0-1.9-0.8-1.9-1.9V21.6
	C6,20.5,6.8,19.7,7.9,19.7z"
      />
      <path
        id="Path-3"
        className={classes.st2}
        d="M10.7,19.7v-8.2C11.5,4.5,15.3,1,21.9,1s10.4,3.5,11.3,10.5"
      />
      <path
        id="Stroke-37"
        className={classes.st3}
        d="M17.2,39.3c2.8-6.3,4.4-7.8,7.1-11.6L17.2,39.3z M26.8,25.4c2.8,5-2.9,12.2-8.6,11.3
	C15.4,31.7,21.1,24.5,26.8,25.4z"
      />
    </SvgIcon>
  );
};

export default LockIcon;
