import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Chip,
  TextField,
  Button,
  IconButton,
  Avatar,
  Drawer,
  MenuItem,
  InputAdornment,
  Dialog,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import EcoIcon from "@material-ui/icons/Eco";
import PlantIcon from "../icons/PlantIcon";
import AdoptionIcon from "../icons/AdoptionIcon";
import RepotedIcon from "../icons/RepotedIcon";
import FrequencyIcon from "../icons/FrequencyIcon";
import WateredIcon from "../icons/WateredIcon";
import InstructionsIcon from "../icons/InstructionsIcon";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CalendarIcon from "../icons/CalendarIcon";
import LoaderButton from "../components/LoaderButton";
import WaterButton from "../components/WaterButton";
import { useParams, useHistory } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import config from "../config";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { s3Upload } from "../libs/awsLib";
import FormatDate from "../libs/dateLib";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatISO } from "date-fns";

//Material-ui - initialize theme & define custom CSS classes
const useStyles = makeStyles((theme) => ({
  avatar: {},
  empty: {},
  attributes: {},
  water: {},
  location: {},
  frequency: {},
  adornmentDays: {},
  subtitle: {},
  paper: {},
  instructions: {},
  close: {},
  section: {},
  submit: {},
  editButton: {},
  waterButton: {},
  deleteButton: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
  back: {
    marginLeft: "8px",
    marginTop: "8px",
    color: "#000",
    position: "absolute",
    zIndex: "1",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  plantView: {
    paddingBottom: "30px",
    "& $avatar": {
      width: "100%",
      height: "414px",
    },
    "& $attributes": {
      position: "relative",
      backgroundColor: theme.palette.aspen.main,
      borderRadius: "19px",
      marginTop: "-60px",
      padding: "20px 14px 6px 14px",
      zIndex: "1",
      "& $water": {
        position: "absolute",
        top: "-28px",
        right: "22px",
      },
      "& $location": {
        float: "right",
      },
      "& $subtitle": {
        position: "relative",
        overflow: "hidden",
        fontSize: "1.375rem",
        marginTop: "27px",
        marginBottom: "14px",
        "&:after": {
          content: '""',
          display: "inline-block",
          position: "absolute",
          width: "100%",
          height: "100%",
          bottom: "50%",
          marginLeft: "16px",
          borderBottom: `1px solid ${theme.palette.secondary.main}`,
        },
      },
      "& $paper": {
        borderRadius: "8px",
        backgroundColor: "#FFF",
        width: "auto",
        margin: "11px 0px",
        "&$instructions": {
          backgroundColor: "transparent",
          border: `1px solid ${theme.palette.secondary.main}`,
          width: "100%",
        },
      },
      "& h4": {
        textTransform: "uppercase",
      },
      "& p": {
        fontWeight: "500",
      },
      "& $waterButton": {
        marginTop: "36px",
        marginBottom: "27px",
      },
      "& $editButton": {},
      "& $deleteButton": {},
    },
  },
  plantEdit: {
    "& .MuiDrawer-paper": {
      backgroundColor: theme.palette.aspen.main,
    },
    "& $close": {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#000",
    },
    "& $section": {
      marginTop: theme.spacing(6),
    },
    "& form": {
      paddingBottom: "30px",
    },
    "& $avatar": {
      width: theme.spacing(20),
      height: theme.spacing(20),
      marginTop: theme.spacing(2),
      borderRadius: "2px",
      borderWidth: "2px",
      backgroundColor: theme.palette.common.white,
      textDecoration: "none",
      cursor: "pointer",
      "&$empty": {
        borderColor: theme.palette.primary.main,
        borderStyle: "dashed",
        borderWidth: "2px",
      },
    },
    "& $frequency": {
      minWidth: "1ch",
    },
    "& $adornmentDays": {
      marginTop: "17px",
    },
    "& $submit": {
      marginTop: "25px",
    },
  },
}));

export default function Plants() {
  const classes = useStyles(); //Create CSS classes
  const file = useRef(null); //Capture uploaded file to display live
  const [fileUrl, setFileUrl] = useState("");
  const { id } = useParams(); //Get current plant id
  const history = useHistory(); //Go back to home page after submission
  const { notificationsRef } = useAppContext(); //Get ref function to open in-app notifcations
  //Define all possible plant locations for dropdown
  const locations = [
    "Balcony",
    "Bathroom",
    "Bedroom",
    "Dining Room",
    "Hall",
    "Kitchen",
    "Living Room",
    "Office",
    "Patio",
    "Porch",
    "Terrace",
  ];
  //Capture plant data & input from form field
  const [plant, setPlant] = useState(null);
  const [plantName, setPlantName] = useState("");
  const [plantNickname, setPlantNickname] = useState("");
  const [plantLocation, setPlantLocation] = useState("");
  const [plantAdoptionDate, setPlantAdoptionDate] = useState(null);
  const [plantRepotedAt, setPlantRepotedAt] = useState(null);
  const [plantWateredAt, setPlantWateredAt] = useState(null);
  const [plantFrequency, setPlantFrequency] = useState("");
  const [plantNotes, setPlantNotes] = useState("");
  //Capture state of the page
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const todayString = new Date().toISOString().split("T")[0];

  useEffect(() => {
    // API request GET plant from plantId
    function loadPlant() {
      return API.get("plants", `/plants/${id}`);
    }

    // Get plant details
    async function onLoad() {
      try {
        const plant = await loadPlant();
        const {
          plantName,
          plantNickname,
          plantLocation,
          plantAdoptionDate,
          plantWateredAt,
          plantFrequency,
          plantRepotedAt,
          plantNotes,
          attachment,
        } = plant;
        if (attachment) {
          plant.attachmentURL = await Storage.vault.get(attachment);
        }
        // setContent(content);
        setPlantName(plantName);
        setPlantNickname(plantNickname);
        setPlantLocation(plantLocation);
        setPlantAdoptionDate(plantAdoptionDate);
        setPlantWateredAt(plantWateredAt);
        setPlantFrequency(plantFrequency);
        setPlantRepotedAt(plantRepotedAt);
        setPlantNotes(plantNotes);
        setPlant(plant);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  function validateForm() {
    return plantName.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
    if (file.current) {
      const reader = new FileReader();
      reader.onload = function () {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(file.current);
    }
  }

  function savePlant(plant) {
    return API.put("plants", `/plants/${id}`, {
      body: plant,
    });
  }

  async function handleSubmit(event) {
    let attachment;

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      if (file.current) {
        attachment = await s3Upload(file.current);
      }

      await savePlant({
        //content,
        plantName,
        plantNickname,
        plantLocation,
        plantAdoptionDate,
        plantWateredAt,
        plantFrequency,
        plantRepotedAt,
        plantNotes,
        attachment: attachment || plant.attachment,
      });
      //history.push("/");
      setDrawerIsOpen();
      setIsLoading(false);
      notificationsRef.current.open("success", "Plant updated");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsLoading(false);
    }
  }

  async function water() {
    const today = new Date().toISOString().split(".")[0];
    //setPlantWateredAt(today);
    const newPlant = {
      ...plant,
      plantWateredAt: today,
    };
    try {
      await savePlant(newPlant);
      setPlantWateredAt(today);
      notificationsRef.current.open("success", "Plant watered");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
    }
  }

  function deletePlant() {
    return API.del("plants", `/plants/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    setIsDeleting(true);
    setDialogIsOpen();

    try {
      await deletePlant();
      history.push("/");
      notificationsRef.current.open("success", "Plant deleted");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
      notificationsRef.current.open("error", e.message);
    }
  }

  function toggleDrawer() {
    setDrawerIsOpen(!drawerIsOpen);
  }

  function toggleDialog() {
    setDialogIsOpen(!dialogIsOpen);
  }

  function renderPlant() {
    return (
      plant && (
        <>
          <IconButton
            aria-label="back"
            className={classes.back}
            component={RouterLink}
            to="/"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Container
            component="main"
            maxWidth="sm"
            className={classes.plantView}
            disableGutters
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item>
                <Avatar
                  src={plant.attachmentURL}
                  variant="rounded"
                  className={classes.avatar}
                >
                  <EcoIcon fontSize="large" />
                </Avatar>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="flex-end"
                className={classes.attributes}
              >
                <WaterButton
                  disabled={
                    plantWateredAt.split("T")[0] === todayString ? true : false
                  }
                  variant="fab"
                  color="primary"
                  className={classes.water}
                  onClick={() => water()}
                />
                <Grid item xs={8}>
                  <Typography variant="h2" className={classes.ellipsis}>
                    {plantNickname}
                  </Typography>
                  <Typography variant="body2" className={classes.ellipsis}>
                    {plantName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Chip
                    variant="outlined"
                    color="secondary"
                    className={classes.location}
                    label={plantLocation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.subtitle}>
                    Plant care
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={3}
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    className={classes.paper}
                  >
                    <Grid item>
                      <AdoptionIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="secondary">
                        Adopted on
                      </Typography>
                      <Typography variant="body1">
                        <FormatDate value={plantAdoptionDate} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    className={classes.paper}
                  >
                    <Grid item>
                      <RepotedIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="secondary">
                        Last repot
                      </Typography>
                      <Typography variant="body1">
                        <FormatDate value={plantRepotedAt} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    className={classes.paper}
                  >
                    <Grid item>
                      <FrequencyIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="secondary">
                        Water
                      </Typography>
                      <Typography variant="body1">
                        Every {plantFrequency} day
                        {plantFrequency > 1 ? "s" : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={1}
                    className={classes.paper}
                  >
                    <Grid item>
                      <WateredIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="secondary">
                        Last watering
                      </Typography>
                      <Typography variant="body1">
                        <FormatDate value={plantWateredAt} />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={1}
                    className={`${classes.paper} ${classes.instructions}`}
                  >
                    <Grid item>
                      <InstructionsIcon />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" color="secondary">
                        Instructions
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {plantNotes ? plantNotes : <i>Write something here</i>}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <WaterButton
                      disabled={
                        plantWateredAt.split("T")[0] === todayString
                          ? true
                          : false
                      }
                      fullWidth
                      color="primary"
                      className={classes.waterButton}
                      onClick={() => water()}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ paddingRight: "11px" }}>
                    <Button
                      fullWidth
                      disableElevation
                      color="secondary"
                      variant="contained"
                      size="large"
                      className={classes.editButton}
                      onClick={toggleDrawer}
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: "11px" }}>
                    <LoaderButton
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.deleteButton}
                      onClick={toggleDialog}
                      isLoading={isDeleting}
                    >
                      Delete
                    </LoaderButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </>
      )
    );
  }

  function renderEdit() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Drawer
          anchor="bottom"
          open={drawerIsOpen}
          className={classes.plantEdit}
          onClose={toggleDrawer}
        >
          <IconButton
            aria-label="close"
            className={classes.close}
            onClick={toggleDrawer}
          >
            <CloseIcon />
          </IconButton>
          <Container component="main" maxWidth="sm">
            <div className={classes.section}>
              <Typography variant="h2" gutterBottom>
                Edit Plant
              </Typography>
            </div>
            {plant && (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item style={{ marginBottom: "18px" }}>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="raised-button-file">
                      <Avatar
                        variant="rounded"
                        className={`${classes.avatar} ${
                          !fileUrl && !plant.attachment && classes.empty
                        }`}
                      >
                        {fileUrl ? (
                          <img
                            src={fileUrl}
                            alt="plant profile"
                            width="100%"
                            height="auto"
                          />
                        ) : !plant.attachment ? (
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Grid item xs={12} align="center">
                              <PlantIcon fontSize="large" />
                            </Grid>
                            <Grid item xs={12} align="center">
                              <Typography variant="body2" color="primary">
                                Click here to add a picture
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <img
                            src={plant.attachmentURL}
                            alt="plant profile"
                            width="100%"
                            height="auto"
                          />
                        )}
                      </Avatar>
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="plantName"
                      label="Name"
                      InputLabelProps={{ required: false }}
                      defaultValue={!plantName ? "" : plantName}
                      onChange={(e) => setPlantName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="plantNickname"
                      label="Nickname"
                      InputLabelProps={{ required: false }}
                      defaultValue={!plantNickname ? "" : plantNickname}
                      onChange={(e) => setPlantNickname(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      select
                      id="plantLocation"
                      label="Location"
                      InputLabelProps={{ required: false }}
                      defaultValue={!plantLocation ? "" : plantLocation}
                      value={!plantLocation ? "" : plantLocation}
                      onChange={(e) => setPlantLocation(e.target.value)}
                    >
                      {locations.map((location) => {
                        return (
                          <MenuItem key={location} value={location}>
                            {location}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <Typography variant="h2" gutterBottom>
                      Plant Care
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      required
                      id="plantAdoptionDate"
                      label="Adoption date"
                      format="dd/MM/yyyy"
                      placeholder="dd/mm/yyyy"
                      maxDate={new Date()}
                      InputLabelProps={{
                        required: false,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={plantAdoptionDate}
                      onChange={(date) => setPlantAdoptionDate(formatISO(date))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      required
                      id="plantRepotedAt"
                      label="Last repoted date"
                      format="dd/MM/yyyy"
                      placeholder="dd/mm/yyyy"
                      maxDate={new Date()}
                      InputLabelProps={{
                        required: false,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={plantRepotedAt}
                      onChange={(date) => setPlantRepotedAt(formatISO(date))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      required
                      id="plantWateredAt"
                      label="Last watered date"
                      format="dd/MM/yyyy"
                      placeholder="dd/mm/yyyy"
                      maxDate={new Date()}
                      InputLabelProps={{
                        required: false,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                      }}
                      value={plantWateredAt}
                      onChange={(date) => setPlantWateredAt(formatISO(date))}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type="number"
                      id="plantFrequency"
                      label="Watering frequency"
                      helperText="How often does this plant need water, in days"
                      InputLabelProps={{ required: false }}
                      inputProps={{
                        className: classes.frequency,
                        style: { width: `${plantFrequency.length}ch` },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={plantFrequency && "notEmpty"}
                          >
                            Every
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={`${classes.adornmentDays} ${
                              plantFrequency && "notEmpty"
                            }`}
                          >
                            days
                          </InputAdornment>
                        ),
                      }}
                      defaultValue={!plantFrequency ? "" : plantFrequency}
                      onChange={(e) => setPlantFrequency(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <Typography variant="h2" gutterBottom>
                      Instructions
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      id="plantNotes"
                      label="Notes (optional)"
                      defaultValue={!plantNotes ? "" : plantNotes}
                      onChange={(e) => setPlantNotes(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoaderButton
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      className={classes.submit}
                      disabled={!validateForm()}
                      isLoading={isLoading}
                    >
                      Save changes
                    </LoaderButton>
                  </Grid>
                  <Grid item xs={12}>
                    <LoaderButton
                      fullWidth
                      disableElevation
                      variant="contained"
                      color="secondary"
                      size="large"
                      className={classes.deleteButton}
                      onClick={toggleDialog}
                      isLoading={isDeleting}
                    >
                      Delete
                    </LoaderButton>
                  </Grid>
                </Grid>
              </form>
            )}
          </Container>
          <Dialog
            open={dialogIsOpen}
            onClose={toggleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Do you really want to delete {plantNickname}?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Once deleted, you won't be able to recover it.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDelete} color="primary" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Drawer>
      </MuiPickersUtilsProvider>
    );
  }

  function renderDialog() {
    return (
      <Dialog
        open={dialogIsOpen}
        onClose={toggleDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you really want to delete {plantNickname}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted, you won't be able to recover it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      {renderDialog()}
      {renderPlant()}
      {renderEdit()}
    </>
  );
}
