import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PlantIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M11.9,13.3C12.3,8.8,11,5.8,7.7,3 M12,11.4c0.1-2.1,1.5-4.8,3.2-6.2"
      />
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M16.8,3.2c-1-0.2-2.3,0.2-3.2,1.3c-0.9,1.1-1.1,2.4-0.7,3.4c1,0.2,2.3-0.2,3.2-1.3
	C17,5.4,17.2,4.1,16.8,3.2z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M5.9,1.1c-0.4,1.2,0,2.8,1.1,4s2.8,1.7,4,1.3c0.4-1.2,0-2.8-1.1-4C8.7,1.2,7.1,0.7,5.9,1.1z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M12,11.5c-3.8,0-6.8,0.5-6.8,1.1s3,1.1,6.8,1.1s6.8-0.5,6.8-1.1S15.8,11.5,12,11.5z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M5.2,15.1c0,0.6,3,1.1,6.8,1.1s6.8-0.5,6.8-1.1v-2.4c0,0.6-3,1.1-6.8,1.1s-6.8-0.5-6.8-1.1V15.1
	z"
      />
      <path
        style={{
          fill: "none",
          stroke: "#517A6A",
          strokeWidth: "1.1652",
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
        d="M7.1,22.2c-0.3-2.5-0.5-4.2-0.8-6.5c1.2,0.3,3.3,0.5,5.7,0.5s4.4-0.2,5.7-0.5
	c-0.3,2.2-0.5,3.9-0.8,6.5c0,0.5-2.2,0.8-4.9,0.8S7.1,22.6,7.1,22.2z"
      />
    </SvgIcon>
  );
};

export default PlantIcon;
