import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Chip,
  Avatar,
  ListItemAvatar,
} from "@material-ui/core";
import EcoIcon from "@material-ui/icons/Eco";
import WaterButton from "./WaterButton";
import DueAt from "./DueAt";
import { withStyles } from "@material-ui/core/styles";
import Storage from "@aws-amplify/storage";

const styles = (theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  chip: {
    marginTop: theme.spacing(1),
  },
  listItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  listItemText: {
    paddingLeft: theme.spacing(1),
  },
  dueAtText: {
    fontSize: "0.8rem",
    lineHeight: "1",
    marginTop: "2px",
    display: "block",
  },
  water: {
    marginRight: "-16px",
  },
});

class GardenPlant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      todayString: new Date().toISOString().split("T")[0],
    };
  }

  componentDidMount() {
    const { plant } = this.props;
    plant.attachment &&
      Storage.vault.get(plant.attachment).then((response) => {
        this.setState({ attachmentUrl: response });
      });
  }

  isLoading = () => {
    !this.state.isLoading
      ? this.setState({ isLoading: true })
      : this.setState({ isLoading: false });
  };

  render() {
    const { classes, plant, water } = this.props;
    return (
      <>
        <ListItem
          button
          disableGutters
          className={classes.listItem}
          key={plant.plantId}
          component={Link}
          to={`/plants/${plant.plantId}`}
          alignItems="flex-start"
        >
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              src={this.state.attachmentUrl}
              className={classes.avatar}
            >
              <EcoIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            className={classes.listItemText}
            primary={
              <Typography variant="h4">{plant.plantNickname}</Typography>
            }
            secondary={
              <>
                <DueAt
                  wateredAt={plant.plantWateredAt}
                  frequency={plant.plantFrequency}
                  className={classes.dueAtText}
                />
                <Chip
                  variant="outlined"
                  color="secondary"
                  size="small"
                  className={classes.chip}
                  label={plant.plantLocation}
                />
              </>
            }
          />
          <ListItemSecondaryAction>
            <WaterButton
              disabled={
                plant.plantWateredAt.split("T")[0] === this.state.todayString
                  ? true
                  : false
              }
              variant="button"
              color="secondary"
              className={classes.water}
              onClick={() => water(plant.plantId)}
              isLoading={this.state.isLoading}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(GardenPlant);
