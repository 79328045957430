import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  Link,
  Grid,
  // eslint-disable-next-line
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockIcon from "../icons/LockIcon";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";

/* function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://plantiful.io/">
        plantiful.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
} */

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: "12px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  mask: {
    cursor: "pointer",
    fill: theme.palette.london.main,
  },
  submit: {
    margin: theme.spacing(8, 0, 2),
  },
  alternate: {
    display: "block",
    margin: theme.spacing(4, 0, 0),
  },
}));

export default function Login() {
  const classes = useStyles();
  const { userHasAuthenticated, notificationsRef } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const [passwordMask, setPasswordMask] = useState(true);
  const [errors, setErrors] = useState({});

  function validateForm() {
    return Object.values(errors).every((x) => x === "");
  }

  function validateInput(e) {
    let inputFeedback = {};
    inputFeedback.email = /^$|.+@.+\..+/.test(fields.email)
      ? ""
      : "Your email must be a valid email address.";
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    inputFeedback.password =
      fields.password.length === 0 || strongRegex.test(fields.password)
        ? ""
        : "Your password must contain at least 8 characters including an uppercase, a lowercase and a number.";
    setErrors({
      ...inputFeedback,
    });
  }

  function togglePasswordMask(e) {
    passwordMask ? setPasswordMask(false) : setPasswordMask(true);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsLoading(false);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <LockIcon />
        <Typography component="h1" variant="h2" className={classes.title}>
          Log in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            required
            fullWidth
            autoFocus
            margin="normal"
            id="email"
            type="email"
            label="Email address"
            name="email"
            autoComplete="email"
            InputLabelProps={{ required: false }}
            value={fields.email}
            onChange={handleFieldChange}
            onBlur={validateInput}
            {...(errors.email && { error: true, helperText: errors.email })}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            id="password"
            type={passwordMask ? "password" : "text"}
            label="Password"
            name="password"
            autoComplete="current-password"
            InputLabelProps={{ required: false }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordMask ? (
                    <Visibility
                      className={classes.mask}
                      onClick={togglePasswordMask}
                    />
                  ) : (
                    <VisibilityOff
                      className={classes.mask}
                      onClick={togglePasswordMask}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            value={fields.password}
            onChange={handleFieldChange}
            onBlur={validateInput}
            {...(errors.password && {
              error: true,
              helperText: errors.password,
            })}
          />
          <Link component={RouterLink} to="/login/reset" variant="body2">
            Forgot password?
          </Link>
          <LoaderButton
            fullWidth
            disableElevation
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.submit}
            disabled={!validateForm()}
            isLoading={isLoading}
          >
            Log in
          </LoaderButton>
          <Grid container justify="center">
            <Grid item>
              <Link
                component={RouterLink}
                to="/signup"
                variant="body2"
                className={classes.alternate}
              >
                <i>Don't have an account? Sign up</i>
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </Container>
  );
}
