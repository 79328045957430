import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Link,
  /* Box, */
  Typography,
  Container,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockIcon from "../icons/LockIcon";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";

/* function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://plantiful.io/">
        plantiful.io
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
} */

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: "12px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  mask: {
    cursor: "pointer",
    fill: theme.palette.london.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  success: {
    textAlign: "center",
  },
  alternate: {
    display: "block",
    margin: theme.spacing(4, 0, 0),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const { notificationsRef } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordMask, setPasswordMask] = useState(true);
  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);

  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validateResetForm() {
    return (
      fields.code.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function togglePasswordMask(e) {
    passwordMask ? setPasswordMask(false) : setPasswordMask(true);
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();
    setIsSendingCode(true);
    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();
    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsConfirming(false);
    }
  }

  function renderRequestCodeForm() {
    return (
      <form className={classes.form} onSubmit={handleSendCodeClick}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="email"
          type="email"
          label="Email"
          name="email"
          InputLabelProps={{ required: false }}
          value={fields.email}
          onChange={handleFieldChange}
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className={classes.submit}
          isLoading={isSendingCode}
          disabled={!validateCodeForm()}
        >
          Send confirmation email
        </LoaderButton>
        <Grid container justify="center">
          <Grid item>
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              className={classes.alternate}
            >
              Nevermind, found it! Take me back to log in
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }

  function renderConfirmationForm() {
    return (
      <form className={classes.form} onSubmit={handleConfirmClick}>
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="code"
          type="tel"
          label="Confirmation code"
          name="confirmationCode"
          InputLabelProps={{ required: false }}
          helperText="Please check your email for the confirmation code."
          value={fields.code}
          onChange={handleFieldChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          id="password"
          type={passwordMask ? "password" : "text"}
          label="New password"
          name="newPassword"
          InputLabelProps={{ required: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordMask ? (
                  <Visibility
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                ) : (
                  <VisibilityOff
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={fields.password}
          onChange={handleFieldChange}
        />
        <TextField
          required
          fullWidth
          margin="normal"
          id="confirmPassword"
          type={passwordMask ? "password" : "text"}
          label="Confirm password"
          name="confirmPassword"
          InputLabelProps={{ required: false }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordMask ? (
                  <Visibility
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                ) : (
                  <VisibilityOff
                    className={classes.mask}
                    onClick={togglePasswordMask}
                  />
                )}
              </InputAdornment>
            ),
          }}
          value={fields.confirmPassword}
          onChange={handleFieldChange}
        />
        <LoaderButton
          fullWidth
          disableElevation
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          className={classes.submit}
          isLoading={isConfirming}
          disabled={!validateResetForm()}
        >
          Confirm
        </LoaderButton>
        <Grid container justify="center">
          <Grid item>
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              className={classes.alternate}
            >
              Nevermind, found it! Take me back to log in
            </Link>
          </Grid>
        </Grid>
      </form>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className={classes.success}>
        <p>Your password has been reset.</p>
        <p>Click below to login with your new credentials.</p>
        <p style={{ marginTop: "64px" }}>
          <Button
            fullWidth
            disableElevation
            color="primary"
            variant="contained"
            size="large"
            component={RouterLink}
            to="/login"
          >
            Go to log in
          </Button>
        </p>
      </div>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <LockIcon />
        <Typography component="h1" variant="h2" className={classes.title}>
          Reset password
        </Typography>
        {!codeSent
          ? renderRequestCodeForm()
          : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}
      </div>
      {/* <Box mt={5}>
        <Copyright />
      </Box> */}
    </Container>
  );
}
