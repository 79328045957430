import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: "45px",
    height: "45px",
  },
  st0: {
    fill: "#F9F1E7",
  },
  st1: {
    fill: "none",
    stroke: "#F7CFB2",
    strokeWidth: "1.7",
    strokeLinecap: "round",
    strokeLinejoin: "round",
  },
}));

const AdoptionIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon viewBox="0 0 45 45" className={classes.icon} {...props}>
      <circle id="Oval" className={classes.st0} cx="30.5" cy="19.5" r="13.5" />
      <path
        id="Stroke-21"
        className={classes.st1}
        d="M1,39.6c2.6-1.9,5.2-3.2,7.1-5.5c1.3-1.5,1.3-4.7,6.1-6.7c2.9-1.2,5-2.3,7.2-3.3
	c1.4-0.7,2.7-1.1,3.6,0.1c0.8,1.1,0.1,2.2-0.9,3c-2.1,1.5-3.3,2.4-5.8,3.9c3.8,0.2,5.8,0.2,11.1-3.1c1-0.6,4.6-4.2,5.6-5.2
	c0.7-0.7,1.7-1,2.4-0.3c0.7,0.6,0.7,1.7,0.1,2.4c-1.2,1.5-3.8,4.8-4.9,5.9c-1.5,1.5-5.8,4.9-8.2,5.4c-3.9,0.9-7.2,0.5-11.2,4"
      />
      <path
        id="Stroke-22"
        className={classes.st1}
        d="M28,24.4c-0.8-7.1,1.3-11.7,6.6-16.2 M27.9,21.5c-0.2-3.4-2.5-7.6-5.1-9.9"
      />
      <path
        id="Stroke-23"
        className={classes.st1}
        d="M20.2,8.4c1.6-0.4,3.6,0.4,5,2.1c1.4,1.7,1.8,3.8,1.1,5.3c-1.6,0.4-3.6-0.4-5-2.1
	C19.9,12,19.5,9.9,20.2,8.4z"
      />
      <path
        id="Stroke-25"
        className={classes.st1}
        d="M37.6,5.2c0.7,1.9,0.1,4.4-1.8,6.4c-1.9,1.9-4.4,2.7-6.3,2.1c-0.7-1.9-0.1-4.4,1.8-6.4
	C33.1,5.3,35.6,4.6,37.6,5.2z"
      />
    </SvgIcon>
  );
};

export default AdoptionIcon;
