import React, { useRef, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { formatISO } from "date-fns";
import PlantIcon from "../icons/PlantIcon";
import CalendarIcon from "../icons/CalendarIcon";
import CloseIcon from "@material-ui/icons/Close";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { API } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { s3Upload } from "../libs/awsLib";

//Material-ui - initialize theme & define custom CSS classes
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    background: theme.palette.aspen.main,
    /* backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply", */
  },
  close: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#000",
  },
  container: {
    backgroundColor: "transparent",
    paddingTop: "35px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  empty: {},
  avatar: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    marginTop: theme.spacing(2),
    borderWidth: "2px",
    borderRadius: "2px",
    backgroundColor: theme.palette.common.white,
    textDecoration: "none",
    cursor: "pointer",
    "&$empty": {
      borderColor: theme.palette.primary.main,
      borderStyle: "dashed",
      borderWidth: "2px",
    },
  },
  frequency: {
    minWidth: "1ch",
  },
  adornmentDays: {
    marginTop: "17px",
  },
  section: {
    marginTop: theme.spacing(6),
  },
}));

export default function NewPlant() {
  const classes = useStyles(); //Create CSS classes
  const file = useRef(null); //Capture uploaded file to display live
  const history = useHistory(); //Go back to home page after submission
  const { notificationsRef } = useAppContext(); //Get ref function to open in-app notifcations
  //Define all possible plant locations for dropdown
  const locations = [
    "Balcony",
    "Bathroom",
    "Bedroom",
    "Dining Room",
    "Hall",
    "Kitchen",
    "Living Room",
    "Office",
    "Patio",
    "Porch",
    "Terrace",
  ];
  //Capture input from form fields
  const [plantName, setPlantName] = useState("");
  const [plantNickname, setPlantNickname] = useState("");
  const [plantLocation, setPlantLocation] = useState("");
  const [plantAdoptionDate, setPlantAdoptionDate] = useState(null);
  const [plantRepotedAt, setPlantRepotedAt] = useState(null);
  const [plantWateredAt, setPlantWateredAt] = useState(null);
  const [plantFrequency, setPlantFrequency] = useState("");
  const [plantNotes, setPlantNotes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const hiddenFileInput = React.useRef(null);

  //Disable/Enable submit button if all required fields are filled
  function validateForm() {
    return (
      plantName.length > 0 &&
      plantNickname.length > 0 &&
      plantLocation.length > 0 &&
      plantAdoptionDate != null &&
      plantRepotedAt != null &&
      plantWateredAt != null &&
      plantFrequency.length > 0
    );
  }

  //Get uploaded file URL to display picture in real-time
  function handleFileChange(event) {
    file.current = event.target.files[0];
    if (file.current) {
      const reader = new FileReader();
      reader.onload = function () {
        setFileUrl(reader.result);
      };
      reader.readAsDataURL(file.current);
    }
  }

  //Make API requests to upload file attachment to S3 & create plant
  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const attachment = file.current ? await s3Upload(file.current) : null;

      await createPlant({
        plantName,
        plantNickname,
        plantLocation,
        plantAdoptionDate,
        plantWateredAt,
        plantFrequency,
        plantRepotedAt,
        plantNotes,
        attachment,
      });
      history.push("/");
      notificationsRef.current.open("success", "Plant created");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsLoading(false);
    }
  }

  //POST Plant API
  function createPlant(plant) {
    return API.post("plants", "/plants", {
      body: plant,
    });
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.root}>
        <IconButton
          aria-label="close"
          className={classes.close}
          component={RouterLink}
          to={"/"}
        >
          <CloseIcon />
        </IconButton>
        <Container component="main" maxWidth="sm" className={classes.container}>
          <Typography variant="h2" gutterBottom>
            {!plantNickname ? "New Plant" : plantNickname}
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item style={{ marginBottom: "18px" }}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  ref={hiddenFileInput}
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="raised-button-file">
                  <Avatar
                    variant="square"
                    className={`${classes.avatar} ${!fileUrl && classes.empty}`}
                  >
                    {!fileUrl ? (
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} align="center">
                          <PlantIcon fontSize="large" />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Typography variant="body2" color="primary">
                            Click here to add a picture
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <img
                        src={fileUrl}
                        alt="plant profile"
                        width="100%"
                        height="auto"
                      />
                    )}
                  </Avatar>
                </label>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="plantName"
                  label="Scientific Name"
                  InputLabelProps={{ required: false }}
                  value={plantName}
                  onChange={(e) => setPlantName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="plantNickname"
                  label="Nickname"
                  InputLabelProps={{ required: false }}
                  helperText="This will be used in your dashboard"
                  value={plantNickname}
                  onChange={(e) => setPlantNickname(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  select
                  id="plantLocation"
                  label="Location"
                  InputLabelProps={{ required: false }}
                  value={plantLocation}
                  onChange={(e) => setPlantLocation(e.target.value)}
                >
                  {locations.map((location) => {
                    return (
                      <MenuItem key={location} value={location}>
                        {location}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant="h2" gutterBottom>
                  Plant Care
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  required
                  id="plantAdoptionDate"
                  label="Adoption date"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  maxDate={new Date()}
                  InputLabelProps={{
                    required: false,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={plantAdoptionDate}
                  onChange={(date) => setPlantAdoptionDate(formatISO(date))}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  required
                  id="plantRepotedAt"
                  label="Last repoted date"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  maxDate={new Date()}
                  InputLabelProps={{
                    required: false,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={plantRepotedAt}
                  onChange={(date) => setPlantRepotedAt(formatISO(date))}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  required
                  id="plantWateredAt"
                  label="Last watered date"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  maxDate={new Date()}
                  InputLabelProps={{
                    required: false,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={plantWateredAt}
                  onChange={(date) => setPlantWateredAt(formatISO(date))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type="number"
                  id="plantFrequency"
                  label="Watering frequency"
                  helperText="How often does this plant need water, in days"
                  InputLabelProps={{ required: false }}
                  inputProps={{
                    className: classes.frequency,
                    style: { width: `${plantFrequency.length}ch` },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={plantFrequency && "notEmpty"}
                      >
                        Every
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={`${classes.adornmentDays} ${
                          plantFrequency && "notEmpty"
                        }`}
                      >
                        days
                      </InputAdornment>
                    ),
                  }}
                  value={plantFrequency}
                  onChange={(e) => setPlantFrequency(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={classes.section}>
                <Typography variant="h2" gutterBottom>
                  Instructions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  id="plantNotes"
                  label="Your notes (optional)"
                  value={plantNotes}
                  onChange={(e) => setPlantNotes(e.target.value)}
                />
              </Grid>
            </Grid>
            <LoaderButton
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.submit}
              disabled={!validateForm()}
              isLoading={isLoading}
            >
              Create plant
            </LoaderButton>
          </form>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
}
