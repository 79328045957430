import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Paper, List, ListItem, Typography } from "@material-ui/core";
import PlantIcon from "../icons/PlantIcon";
import ToggleButton from "./ToggleButton";
import GardenPlant from "./GardenPlant";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "76px",
  },
  body: {
    color: theme.palette.london.main,
  },
  start: {
    padding: "39px",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main,
    borderStyle: "dashed",
    borderRadius: "10px",
    textDecoration: "none",
    marginBottom: "26px",
  },
  ghost: {
    backgroundColor: theme.palette.primary.main,
    opacity: "10%",
    borderRadius: "10px",
    height: "76px",
  },
  horizontalList: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    overflowY: "hidden",
    marginLeft: "-22px",
    marginRight: "-22px",
    paddingLeft: "22px",
    msOverflowStyle: "none",
    scrollbarWidth: "none" /* Firefox */,
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  horizontalItem: {
    width: "initial",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));

const GardenList = (props) => {
  const classes = useStyles();
  const { plants, updatePlant } = props;
  const locations = [...new Set(plants.map((plant) => plant.plantLocation))];
  const [filter, setFilter] = useState("All");

  function handleWater(plantId) {
    updatePlant(plantId);
  }

  return (
    <div className={classes.root}>
      {plants.length === 0 ? (
        <>
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            className={classes.start}
            component={RouterLink}
            to="/plants/new"
          >
            <Grid item>
              <PlantIcon fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h4" component="h3" color="primary">
                Let's start
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                align="center"
                className={classes.body}
              >
                Click here to add your first plant
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4} sm={2}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Paper className={classes.ghost}></Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <List className={classes.horizontalList}>
            <ListItem key="All" className={classes.horizontalItem}>
              <ToggleButton
                color="secondary"
                label="All"
                isPressed={"All" === filter}
                setFilter={setFilter}
              />
            </ListItem>
            {locations.map((location) => (
              <ListItem
                key={location.replace(/[^a-zA-Z ]/g, "")}
                className={classes.horizontalItem}
              >
                <ToggleButton
                  color="secondary"
                  label={location}
                  isPressed={location === filter}
                  setFilter={setFilter}
                />
              </ListItem>
            ))}
          </List>
          <List>
            {filter === "All"
              ? plants.map((plant) => (
                  <GardenPlant
                    key={plant.plantId}
                    water={handleWater}
                    plant={plant}
                  />
                ))
              : plants
                  .filter((plant) => plant.plantLocation === filter)
                  .map((filteredPlant) => (
                    <GardenPlant
                      key={filteredPlant.plantId}
                      water={handleWater}
                      plant={filteredPlant}
                    />
                  ))}
          </List>
        </>
      )}
    </div>
  );
};

export default GardenList;
