import React from "react";
import { Chip } from "@material-ui/core";

const ToggleButton = (props) => {
  const { isPressed, setFilter, label, ...rest } = props;
  return (
    <Chip
      variant={isPressed ? "outlined" : "default"}
      label={label}
      onClick={() => setFilter(label)}
      {...rest}
    />
  );
};

export default ToggleButton;
