import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, CircularProgress } from "@material-ui/core";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: "12px",
  },
}));

export default function ConfirmUser() {
  const classes = useStyles();
  const history = useHistory();
  const { notificationsRef } = useAppContext();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get("email");
  const code = query.get("code");

  useEffect(() => {
    handleConfirmationSubmit();

    async function handleConfirmationSubmit() {
      console.log(email, code);

      try {
        await Auth.confirmSignUp(email, code);
        notificationsRef.current.open("success", "Email address verified");
        history.push("/login");
      } catch (e) {
        onError(e);
        notificationsRef.current.open("error", e.message);
        history.push("/");
      }
    }
  }, [email, code, history, notificationsRef]);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <CircularProgress color="primary" />
        <Typography component="h1" variant="h2" className={classes.title}>
          Verifying your email...
        </Typography>
      </div>
    </Container>
  );
}
