import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));
const Notifications = forwardRef((props, ref) => {
  const classes = useStyles(); //Create CSS classes
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  useImperativeHandle(ref, () => ({
    open(type, message) {
      setMessage(message);
      setType(type);
      setOpen(true);
      /* setTimeout(() => {
        setOpen(false);
      }, 3000); */
    },
  }));

  function handleClose(event) {
    setOpen(false);
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={type === "success" ? classes.success : classes.error}
        message={message}
      />
    </Snackbar>
  );
});

export default Notifications;
