import React, { useRef, useState, useEffect } from "react";
import { Auth, Storage } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Container,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import FarmerGreenIcon from "../assets/FarmerGreenIcon.png";
import FlowerBackground from "../assets/FlowerBackground.png";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
//import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { s3Upload } from "../libs/awsLib";
import config from "../config";

const useStyles = makeStyles((theme) => ({
  title: {},
  header: {
    height: "300px",
    maxWidth: "none",
    backgroundImage: `url(${FlowerBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
    borderBottomLeftRadius: "26px",
    borderBottomRightRadius: "26px",
  },
  profileView: {
    marginTop: "-250px",
  },
  empty: {},
  avatar: {
    width: "126px",
    height: "126px",
    margin: "8px 8px 100px 8px",
    backgroundColor: theme.palette.common.white,
    /* borderColor: theme.palette.primary.main,
    borderStyle: "dashed",
    borderWidth: "2px", */
    textDecoration: "none",
    cursor: "pointer",
    "&$empty": {
      borderColor: theme.palette.primary.main,
      borderStyle: "dashed",
      borderWidth: "2px",
    },
    "& img": {
      objectFit: "cover",
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  /* updatePicture: {
    position: "absolute",
    marginTop: "80px",
  }, */
  profileName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  profileNameGrid: {
    maxWidth: "250px",
  },
  profileNameField: {
    marginTop: "-40px",
    backgroundColor: theme.palette.aspen.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  upload: {
    marginTop: theme.spacing(1),
    width: theme.spacing(12),
  },
}));

export default function Profile() {
  const classes = useStyles();
  //const history = useHistory();
  const { notificationsRef } = useAppContext();
  const file = useRef(null);
  const [fileUrl, setFileUrl] = useState("");
  const [profile, setProfile] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  //const [profilePictureButton, setProfilePictureButton] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [showNameField, setShowNameField] = useState(false);

  useEffect(() => {
    // API request GET user info
    function loadUser() {
      return Auth.currentUserInfo();
    }

    // Get user details
    async function onLoad() {
      try {
        const profile = await loadUser();
        const { name, email, picture } = profile.attributes;
        if (picture) {
          profile.attributes.pictureUrl = await Storage.vault.get(picture);
        }
        setProfileName(name);
        setProfileEmail(email);
        setProfilePicture(picture);
        setProfile(profile);
      } catch (e) {
        onError(e);
      }
    }
    onLoad();
  }, []);

  //Trigger hidden file input from edit button
  /* function handleInputClick(event) {
    hiddenFileInput.current.click();
  } */

  //Get uploaded file URL to display picture in real-time
  function handleFileChange(event) {
    file.current = event.target.files[0];
    if (file.current) {
      const reader = new FileReader();
      reader.onload = function () {
        setFileUrl(reader.result);
        handleUpdateClick(event);
        //setProfilePictureButton(true);
      };
      reader.readAsDataURL(file.current);
    }
  }

  //Hide or show name field on click
  function toggleNameField(event) {
    showNameField ? setShowNameField(false) : setShowNameField(true);
  }

  async function handleUpdateClick(event) {
    event.preventDefault();
    setShowNameField(false);

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    try {
      if (file.current) {
        profile.picture = await s3Upload(file.current);
      }
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        name: profileName || "",
        picture: profile.picture || profilePicture || "",
      });
      notificationsRef.current.open("success", "Profile updated");
      //setProfilePictureButton(false);
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      //setProfilePictureButton(false);
    }
  }

  return (
    <>
      <Box className={classes.header}></Box>
      {profile && (
        <Container maxWidth="xs" className={classes.profileView}>
          <form className={classes.form} onSubmit={handleUpdateClick}>
            <Grid container spacing={2} direction="column" justify="flex-start">
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    ref={hiddenFileInput}
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="raised-button-file">
                    <Avatar
                      className={`${classes.avatar} ${
                        !fileUrl && !profile.attributes.picture && classes.empty
                      }`}
                    >
                      {fileUrl ? (
                        <img
                          src={fileUrl}
                          alt="user profile"
                          width="100%"
                          height="100%"
                        />
                      ) : !profile.attributes.picture ? (
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} align="center">
                            <img
                              src={FarmerGreenIcon}
                              alt="farmer black icon"
                              width="43px"
                              height="43px"
                            />
                          </Grid>
                          <Grid item xs={12} align="center">
                            <Typography variant="body2" color="primary">
                              Upload
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        <img
                          src={profile.attributes.pictureUrl}
                          alt="user profile"
                          width="100%"
                          height="100%"
                        />
                      )}
                    </Avatar>
                  </label>
                  {/* {profilePictureButton && (
                    <Button
                      disableElevation
                      variant="contained"
                      color="secondary"
                      aria-label="update picture"
                      component="button"
                      type="submit"
                      className={classes.updatePicture}
                    >
                      Update
                    </Button>
                  )} */}
                </Box>
              </Grid>
              <Grid
                item
                container
                xs={12}
                spacing={1}
                justify="flex-start"
                alignItems="center"
                style={{ position: "relative" }}
              >
                <Grid item className={classes.profileNameGrid}>
                  <Typography variant="h2" className={classes.profileName}>
                    {!profileName ? "Add my name" : profileName}
                  </Typography>
                  {showNameField && (
                    <TextField
                      fullWidth
                      autoFocus
                      variant="outlined"
                      id="profileName"
                      label="Name"
                      defaultValue={!profileName ? "" : profileName}
                      onChange={(e) => setProfileName(e.target.value)}
                      className={classes.profileNameField}
                    />
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    style={{
                      transform: `rotate(${showNameField ? "45deg" : "0deg"})`,
                    }}
                    aria-label="show name field"
                    component="span"
                    onClick={toggleNameField}
                  >
                    {showNameField ? (
                      <AddIcon />
                    ) : profileName ? (
                      <CreateOutlinedIcon />
                    ) : (
                      <AddIcon />
                    )}
                  </IconButton>
                </Grid>
                {showNameField && (
                  <Grid item>
                    <IconButton
                      color="primary"
                      size="small"
                      aria-label="show name field"
                      component="button"
                      type="submit"
                    >
                      <DoneIcon />
                    </IconButton>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="body2">{profileEmail}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                color="secondary"
                size="large"
                component={RouterLink}
                to="/profile/password"
              >
                Edit password
              </Button>
            </Grid>
            {/* <Grid item xs={6} style={{ paddingRight: "11px" }}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                color="secondary"
                size="medium"
                component={RouterLink}
                to="/profile/email"
              >
                Edit email
              </Button>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: "11px" }}>
              <Button
                fullWidth
                disableElevation
                variant="contained"
                color="secondary"
                size="medium"
                component={RouterLink}
                to="/profile/password"
              >
                Edit password
              </Button>
            </Grid> */}
          </Grid>
        </Container>
      )}
    </>
  );
}
