import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Container, Grid, Fab, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlantIcon from "../icons/PlantIcon";
import FlowerBackground from "../assets/FlowerBackground.png";
import LogoVertical from "../assets/logo-vertical.png";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import TodayList from "../components/TodayList";
import GardenList from "../components/GardenList";

//Material-ui - initialize theme & define custom CSS classes
const useStyles = makeStyles((theme) => ({
  home: {
    backgroundColor: theme.palette.aspen.main,
  },
  lander: {
    position: "absolute",
    width: "100%",
    height: "100vh",
    top: "0",
    bottom: "0",
    backgroundImage: `url(${FlowerBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center bottom",
  },
  landerContainer: {
    top: "0",
    bottom: "0",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  logoVertical: {
    left: "50%",
    position: "relative",
    transform: "translateX(-50%)",
  },
  today: {
    backgroundColor: theme.palette.common.white,
    paddingTop: "19px",
    paddingBottom: "30px",
    paddingLeft: "22px",
    paddingRight: "22px",
    borderBottomLeftRadius: "26px",
    borderBottomRightRadius: "26px",
    maxWidth: "100%",
  },
  garden: {
    backgroundColor: theme.palette.aspen.main,
    paddingTop: "26px",
    paddingBottom: "29px",
    paddingLeft: "22px",
    paddingRight: "22px",
    maxWidth: "100%",
  },
  fab: {
    position: "fixed",
    width: "72px",
    height: "72px",
    bottom: "32px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:active": {
      backgroundColor: theme.palette.grey[50],
    },
    "& svg": {
      fontSize: "2rem",
    },
  },
}));

export default function Home() {
  const classes = useStyles(); //Create CSS classes
  const [plants, setPlants] = useState([]); //Hold plants data
  const { isAuthenticated, notificationsRef } = useAppContext(); //Is user authenticated or not
  const [isLoading, setIsLoading] = useState(true); //Is content (plants data) retrieved

  useEffect(() => {
    //Make API request to retrieve plants data
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const plants = await loadPlants();
        setPlants(plants);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  //GET plants API
  function loadPlants() {
    return API.get("plants", "/plants");
  }

  //PUT plants API - update plant wateredAt date when user waters plant from Dashboard
  function updatePlant(plantId) {
    const currentPlant = plants.find((p) => p.plantId === plantId);
    const newPlant = {
      ...currentPlant,
      plantWateredAt: new Date().toISOString().split(".")[0],
    };
    API.put("plants", `/plants/${plantId}`, {
      body: newPlant,
    }).then((res) => {
      setPlants(
        plants.map((plant) => (plant.plantId === plantId ? newPlant : plant))
      );
      notificationsRef.current.open("success", "Plant watered");
    });
  }

  //Render landing page for unauthenticated users
  function renderLander() {
    return (
      <div className={classes.lander}>
        <Container
          className={classes.landerContainer}
          maxWidth="xs"
          disableGutters
        >
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginTop: "125px" }}
          >
            <Grid
              item
              container
              xs={12}
              spacing={4}
              direction="column"
              justify="center"
              alignItems="stretch"
            >
              <Grid item xs={12} style={{ marginBottom: "64px" }}>
                <img
                  src={LogoVertical}
                  className={classes.logoVertical}
                  width="160"
                  alt="logo vertical"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  component={RouterLink}
                  to="/signup"
                >
                  Sign up
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  component={RouterLink}
                  to="/login"
                >
                  Log in
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  //Render plants dashboard for authenticated users
  function renderPlants() {
    return (
      <>
        <Container maxWidth="md" disableGutters>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="stretch"
          >
            <Grid item className={classes.today}>
              <Typography variant="h2" gutterBottom>
                Today
              </Typography>
              {/* Load list of plants that need attention today when plants data is retrieved */}
              {!isLoading && (
                <TodayList plants={plants} updatePlant={updatePlant} />
              )}
            </Grid>
            <Grid item className={classes.garden}>
              <Typography variant="h2" gutterBottom>
                Garden
              </Typography>
              {/* Load list of all plants in garden when plants data is retrieved */}
              {!isLoading && (
                <GardenList plants={plants} updatePlant={updatePlant} />
              )}
            </Grid>
          </Grid>
        </Container>
        {/* Create a new plant button */}
        <Fab
          color="primary"
          aria-label="add"
          size="large"
          className={classes.fab}
          component={RouterLink}
          to="/plants/new"
        >
          <PlantIcon />
        </Fab>
      </>
    );
  }

  return (
    <div className={classes.home}>
      {isAuthenticated ? renderPlants() : renderLander()}
    </div>
  );
}
