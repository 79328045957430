import React, { Component } from "react";
import { Fab, Button } from "@material-ui/core";
import DropletIcon from "../icons/DropletIcon";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  fab: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button: {
    margin: theme.spacing(1),
    borderRadius: "22px",
  },
});

class WaterButton extends Component {
  state = {};

  render() {
    const { classes, className, isLoading, onClick, variant, color, disabled } =
      this.props;
    return (
      <>
        {(() => {
          switch (variant) {
            case "fab":
              return (
                <Fab
                  aria-label="water"
                  className={`${classes.fab} ${className || ""}`}
                  onClick={onClick}
                  disabled={disabled}
                >
                  <DropletIcon isLoading={isLoading} />
                </Fab>
              );
            case "button":
              return (
                <Button
                  aria-label="water"
                  variant="contained"
                  color={color}
                  disabled={disabled}
                  disableElevation
                  className={`${classes.button} ${className || ""}`}
                  startIcon={
                    <DropletIcon
                      isLoading={isLoading}
                      color={!disabled ? color : "primary"}
                    />
                  }
                  onClick={onClick}
                >
                  Water
                </Button>
              );
            default:
              return (
                <Button
                  aria-label="water"
                  variant="contained"
                  fullWidth
                  color={color}
                  disabled={disabled}
                  disableElevation
                  size="large"
                  className={className}
                  startIcon={
                    <DropletIcon
                      isLoading={false}
                      color={!disabled ? color : "primary"}
                    />
                  }
                  onClick={onClick}
                >
                  Water now
                </Button>
              );
          }
        })()}
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(WaterButton);
