import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import Storage from "@aws-amplify/storage";
import { GridListTile, Avatar, Typography } from "@material-ui/core";
import EcoIcon from "@material-ui/icons/Eco";
import WaterButton from "./WaterButton";
import DueAt from "./DueAt";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  gridListTile: {
    height: "100%",
    padding: "5px",
  },
  avatar: {
    width: "215px",
    height: "215px",
  },
  water: {
    position: "relative",
    bottom: "28px",
    left: "50%",
    transform: "translateX(-50%)",
    marginBottom: "-28px",
  },
  nickname: {
    marginTop: "12px",
  },
  dueAt: {
    "& .MuiGrid-root": {
      justifyContent: "center",
    },
  },
});

class TodayPlant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { plant } = this.props;
    plant.attachment &&
      Storage.vault.get(plant.attachment).then((response) => {
        this.setState({ attachmentUrl: response });
      });
  }

  isLoading = () => {
    !this.state.isLoading
      ? this.setState({ isLoading: true })
      : this.setState({ isLoading: false });
  };

  render() {
    const { classes, plant, water } = this.props;
    return (
      <GridListTile className={classes.gridListTile}>
        <Avatar
          variant="rounded"
          src={this.state.attachmentUrl}
          className={classes.avatar}
          component={RouterLink}
          to={`/plants/${plant.plantId}`}
        >
          <EcoIcon fontSize="large" />
        </Avatar>
        <WaterButton
          variant="fab"
          color="primary"
          className={classes.water}
          onClick={() => water(plant.plantId)}
          isLoading={this.state.isLoading}
        />
        <Typography variant="h4" align="center" className={classes.nickname}>
          {plant.plantNickname}
        </Typography>
        <DueAt
          wateredAt={plant.plantWateredAt}
          frequency={plant.plantFrequency}
          className={classes.dueAt}
        />
      </GridListTile>
    );
  }
}

export default withStyles(styles, { withTheme: true })(TodayPlant);
