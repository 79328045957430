import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Container,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import LockIcon from "../icons/LockIcon";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  back: {
    marginLeft: "8px",
    marginTop: "8px",
    color: "#000",
  },
  title: {
    marginTop: "12px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  mask: {
    cursor: "pointer",
    fill: theme.palette.london.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword() {
  const classes = useStyles();
  const history = useHistory();
  const { notificationsRef } = useAppContext();
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const [passwordMask, setPasswordMask] = useState(true);
  const [errors, setErrors] = useState({});
  const [isChanging, setIsChanging] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateInput(e) {
    let inputFeedback = {};
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    inputFeedback.password = strongRegex.test(fields.password)
      ? ""
      : "Your password must contain at least 8 characters including an uppercase, a lowercase and a number.";
    inputFeedback.confirmPassword =
      fields.confirmPassword.length === 0 ||
      fields.password === fields.confirmPassword
        ? ""
        : "This password must match the above password.";
    setErrors({
      ...inputFeedback,
    });
  }

  function togglePasswordMask(e) {
    passwordMask ? setPasswordMask(false) : setPasswordMask(true);
  }

  async function handleChangeClick(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        fields.oldPassword,
        fields.password
      );

      history.push("/profile");
      notificationsRef.current.open("success", "Password changed");
    } catch (e) {
      onError(e);
      notificationsRef.current.open("error", e.message);
      setIsChanging(false);
    }
  }

  return (
    <>
      <IconButton
        aria-label="back"
        className={classes.back}
        component={RouterLink}
        to="/profile"
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <LockIcon />
          <Typography component="h1" variant="h2" className={classes.title}>
            Edit password
          </Typography>
          <form className={classes.form} onSubmit={handleChangeClick}>
            <TextField
              required
              fullWidth
              autoFocus
              margin="normal"
              id="oldPassword"
              type={passwordMask ? "password" : "text"}
              label="Old password"
              name="old password"
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordMask ? (
                      <Visibility
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              value={fields.oldPassword}
              onChange={handleFieldChange}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              id="password"
              type={passwordMask ? "password" : "text"}
              label="New password"
              name="new password"
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordMask ? (
                      <Visibility
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              value={fields.password}
              onChange={handleFieldChange}
              onBlur={validateInput}
              {...(errors.password && {
                error: true,
                helperText: errors.password,
              })}
            />
            <TextField
              required
              fullWidth
              margin="normal"
              id="confirmPassword"
              type={passwordMask ? "password" : "text"}
              label="Confirm password"
              name="confirm password"
              InputLabelProps={{ required: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordMask ? (
                      <Visibility
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    ) : (
                      <VisibilityOff
                        className={classes.mask}
                        onClick={togglePasswordMask}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              value={fields.confirmPassword}
              onChange={handleFieldChange}
              onBlur={validateInput}
              {...(errors.confirmPassword && {
                error: true,
                helperText: errors.confirmPassword,
              })}
            />
            <LoaderButton
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              className={classes.submit}
              isLoading={isChanging}
              disabled={!validateForm()}
            >
              Change password
            </LoaderButton>
          </form>
        </div>
      </Container>
    </>
  );
}
